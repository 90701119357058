<template>
  <div
    id="edit-mcn-business-info-page"
    class="edit-mcn-business-info-page"
  >
    <van-sticky>
      <div class="mcn-info">
        <img
          class="avatar"
          src="@/assets/images/icon-mcn@2x.png"
        >
        <div class="name">
          <span>{{ detail && detail.name ? detail.name : '-' }}</span>
        </div>
      </div>
    </van-sticky>

    <section-text text="编辑机构权益" />

    <div class="edit-business-form">
      <div class="field">
        <div class="title">
          返点比例
        </div>
        <div class="right">
          <input
            class="input mini"
            placeholder="0-100"
            v-model="form.rebateRate"
          ><span class="ext">%</span>
        </div>
      </div>
      <div class="field">
        <div class="title">
          年框梯度
        </div>
        <div class="right">
          <textarea
            class="textarea"
            placeholder="请输入年框梯度"
            v-model="form.rebateGradients"
          />
        </div>
      </div>
      <div class="field">
        <div class="title">
          特别政策
        </div>
        <div class="right">
          <textarea
            class="textarea"
            placeholder="请输入特别政策"
            v-model="form.specialPolicy"
          />
        </div>
      </div>
      <div class="field">
        <div class="title">
          结算返点额外<br>扣税
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('hasRebateTax', actions_1, '结算返点额外扣税')"
          >
            <span>{{ getActionText(actions_1, form.hasRebateTax) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          梯度拉平
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('crossEntropy', actions_1, '梯度拉平')"
          >
            <span>{{ getActionText(actions_1, form.crossEntropy) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          签署年框
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('signYearFrame', actions_1, '签署年框')"
          >
            <span>{{ getActionText(actions_1, form.signYearFrame) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          返点结算周期
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('rebateSettlementPeriod', actions_2, '返点结算周期')"
          >
            <span>{{ getActionText(actions_2, form.rebateSettlementPeriod) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          返点结算方式
        </div>
        <div class="right">
          <div
            class="select mini"
            @click="showSelectAction('rebateSettlementPayment', actions_3, '返点结算方式')"
          >
            <span>{{ getActionText(actions_3, form.rebateSettlementPayment) }}</span>
            <img
              class="arrow"
              src="@/assets/images/icon-down-arrow@2x.png"
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="title">
          备注
        </div>
        <div class="right">
          <textarea
            class="textarea"
            v-model="form.remark"
          />
        </div>
      </div>
    </div>

    <div
      class="bottom-placeholder"
      v-if="hasPermission"
    />
    <div
      class="bottom"
      :style="'width:'+bottomWidth+'px;'"
      v-if="hasPermission"
    >
      <van-button
        class="btn-back"
        @click="back"
      >
        返回上一级
      </van-button>
      <van-button
        class="btn-save"
        :loading="saving"
        @click="save"
      >
        保存
      </van-button>
    </div>

    <van-action-sheet
      v-model:show="showSelectActions"
      :actions="actions"
      cancel-text="取消"
      :description="selectActionDesc"
      close-on-click-action
      @select="handleSelectAction"
    />
  </div>
</template>

<script>
import SectionText from '@/components/SectionText';
import { mapActions } from 'vuex';
import { Toast } from 'vant';

export default {
  name: 'EditMcnBusinessInfo',
  components: { SectionText },
  data() {
    return {
      bottomWidth: 0,
      mcnId: this.$route.params.id,
      detail: undefined,
      hasPermission: true,
      form: {
        rebateRate: null,
        rebateGradients: null,
        specialPolicy: null,
        hasRebateTax: null,
        crossEntropy: null,
        signYearFrame: null,
        rebateSettlementPeriod: null,
        rebateSettlementPayment: null,
        remark: null,
      },
      showSelectActions: false,
      actions: [],
      selectActionType: '',
      selectActionDesc: '',
      actions_1: [
        { name: '是', value: true },
        { name: '否', value: false },
        { name: '暂无', value: null },
      ],
      actions_2: [
        { name: '月度', value: 1 },
        { name: '季度', value: 2 },
        { name: '暂无', value: null },
      ],
      actions_3: [
        { name: '对公', value: 1 },
        { name: '对私', value: 2 },
        { name: '暂无', value: null },
      ],
      saving: false,
    };
  },
  created() {
    this.$nextTick(function () {
      const page_el = document.getElementById('edit-mcn-business-info-page');
      if (page_el) {
        this.bottomWidth = page_el.offsetWidth;
      }
    });
    this.toGetMcnDetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    async toGetMcnDetail() {
      const res = await this.getMcnDetail({ id: this.mcnId });
      if (!res) {
        return;
      }
      this.detail = res;
      this.form.rebateRate = res.rebate_rate !== null ? (res.rebate_rate / 100) : null;
      this.form.rebateGradients = res.rebate_gradients;
      this.form.specialPolicy = res.special_policy;
      this.form.hasRebateTax = res.has_rebate_tax;
      this.form.crossEntropy = res.cross_entropy;
      this.form.signYearFrame = res.sign_year_frame;
      this.form.rebateSettlementPeriod = res.rebate_settlement_period;
      this.form.rebateSettlementPayment = res.rebate_settlement_payment;
      this.form.remark = res.remark;
    },
    async save() {
      let rebateRate = null;
      if (this.form.rebateRate !== null && this.form.rebateRate !== '') {
        if (!/^\d+$/.test(this.form.rebateRate)) {
          Toast.fail('返点比例只支持0-100的整数');
          return;
        }
        rebateRate = parseInt(this.form.rebateRate);
        if (rebateRate < 0 || rebateRate > 100) {
          Toast.fail('返点比例只支持0-100的整数');
          return;
        }
      }

      if (this.form.rebateGradients && this.form.rebateGradients.length > 200) {
        Toast.fail('年框梯度输入长度最大为200');
        return;
      }

      if (this.form.specialPolicy && this.form.specialPolicy.length > 200) {
        Toast.fail('特别政策输入长度最大为200');
        return;
      }

      this.saving = true;
      console.log('rebateRate:'+rebateRate)
      const res = await this.updateMcn(
        {
          id: this.mcnId,
          rebateRate: rebateRate !== null ? rebateRate * 100 : null,
          rebateGradients: this.form.rebateGradients,
          specialPolicy: this.form.specialPolicy || '',
          hasRebateTax: this.form.hasRebateTax  || '',
          crossEntropy: this.form.crossEntropy,
          signYearFrame: this.form.signYearFrame,
          rebateSettlementPeriod: this.form.rebateSettlementPeriod,
          rebateSettlementPayment: this.form.rebateSettlementPayment,
          remark: this.form.remark,
        }
      ).catch((e) => {
        this.saving = false;
      });
      this.saving = false;
      if (!res) {
        return;
      }

      Toast.success('保存成功');
      this.$router.back();
    },
    showSelectAction(type, actions, desc) {
      this.selectActionType = type;
      this.actions = actions;
      this.selectActionDesc = desc;
      this.showSelectActions = true;
    },
    handleSelectAction(action, index) {
      this.form[this.selectActionType] = action.value;
    },
    getActionText(actions, value) {
      let text = '-';
      actions.forEach((x) => {
        if (x.value === value) {
          text = x.name;
        }
      });
      return text;
    },
    ...mapActions({
      getMcnDetail:
        'star/getMcnDetail',
      updateMcn:
        'star/updateMcn',
    }),
  }
};
</script>

<style lang="less" scoped>
.edit-mcn-business-info-page {
  background: #fff;

  .mcn-info {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    box-shadow: 0 4px 10px 0 rgba(231, 231, 231, 0.5);

    .avatar {
      flex-shrink: 0;
      width: 108px;
      height: 108px;
      border-radius: 60px;
    }

    .name {
      width: 100%;
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
      margin-left: 30px;
    }
  }

  .edit-business-form {
    padding: 0 40px 40px;

    .field {
      display: flex;
      align-items: center;

      font-size: 28px;
      font-weight: 400;
      color: #333;
      line-height: 34px;

      &:nth-child(n+2) {
        margin-top: 30px;
      }

      .title {
        flex-shrink: 0;
        width: 220px;
        font-weight: 600;
      }

      .right {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .input {
        width: 100%;
        height: 80px;
        background: #FFFFFF;
        border-radius: 40px;
        border: 1px solid #EBEBEB;
        padding: 0 30px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
      }

      .textarea {
        width: 100%;
        height: 200px;
        border-radius: 20px;
        border: 1px solid #EBEBEB;
        overflow: hidden;
        resize: none;
        padding: 24px 30px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
      }

      .select {
        display: flex;
        position: relative;
        width: 100%;
        height: 80px;
        background: #FFFFFF;
        border-radius: 40px;
        border: 1px solid #EBEBEB;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-right: 30px;

        .arrow {
          width: 20px;
          height: 10px;
          position: absolute;
          right: 20px;
        }
      }

      .mini {
        width: 50%;
        text-align: center;
      }

      .second {
        margin-left: 10px;
      }

      .ext {
        font-size: 28px;
        font-weight: 400;
        color: #333;
        line-height: 34px;
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }

  .bottom-placeholder {
    height: 160px;
  }

  .bottom {
    display: flex;
    align-items: center;
    background: #fff;
    height: 160px;
    border-top: 1px solid #E6E6E6;
    position: fixed;
    bottom: 0;

    .btn-back {
      width: 40%;
      background: #FFFFFF;
      border-radius: 40px;
      border: 1px solid #0095FF;
      font-size: 30px;
      font-weight: 600;
      color: #0095FF;
      line-height: 30px;
      margin-left: 30px;

      &:active {
        opacity: 0.8;
      }
    }

    .btn-save {
      width: 60%;
      background: #0095FF;
      border-radius: 40px;
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 10px;
      margin-right: 30px;

      &:active {
        opacity: 0.8;
      }
    }
  }
}

</style>