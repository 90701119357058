<template>
  <div class="section">
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'SectionText',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
};
</script>

<style lang="less" scoped>
.section {
  padding: 40px 20px;
  .text {
    font-size: 34px;
    font-weight: 600;
    color: #333333;
    line-height: 34px;

    &::before {
      display: inline-block;
      content: ' ';
      width: 10px;
      height: 30px;
      background: #0095FF;
      border-radius: 7px;
      margin-right: 20px;
      vertical-align: middle;
    }
  }
}
</style>